import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import GatsbyImageProps from 'gatsby-image';
import { up } from 'styled-breakpoints';

const TestimonialContainer = styled.blockquote`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 20px;
  flex: 1;
  ${up('md')} {
    padding: 100px 20px;
  }
`;
const TestimonialText = styled.p`
  width: 100%;
  text-transform: none;
  line-height: 1.45;
  margin-bottom: 3rem;
  font-weight: bold;
  text-align: center;
  font-size: 28px;
  ${up('lg')} {
    font-size: 40px;
  }
`;
const TestimonialSource = styled.p`
  font-size: 24px;
  margin-bottom: 2rem;
  text-align: center;
`;
const TestimonialImage = styled.div``;

interface TestimonialProps {
  source: string;
  body: string;
  image: GatsbyImageProps;
}

const Testimonial: React.FC<TestimonialProps> = ({ source, body, image }) => {
  return (
    <TestimonialContainer>
      <TestimonialText>{body}</TestimonialText>
      <TestimonialSource>{source}</TestimonialSource>
      <TestimonialImage>
        {image && (
          <LazyLoad once height="100%">
            <Image fixed={image.fixed} alt={image.title} />
          </LazyLoad>
        )}
      </TestimonialImage>
    </TestimonialContainer>
  );
};

export default Testimonial;

export const query = graphql`
  fragment Testimonial on ContentfulTestimonial {
    source
    body
    image {
      fixed(width: 125, height: 125) {
        ...GatsbyContentfulFixed
      }
    }
  }
`;
