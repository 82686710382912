import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import { Button } from '../../';

const FeaturedCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 60px;
  ${up('md')} {
    margin-bottom: 120px;
  }
`;

const Imagery = styled.div`
  width: 100%;
  img {
    max-width: 100%;
  }
  ${up('md')} {
    width: 50%;
  }
`;

const Content = styled.div`
  width: 100%;
  ${up('md')} {
    width: 50%;
    padding-left: 20px;
  }

  ${up('lg')} {
    padding-left: 90px;
  }
`;

const FeaturedBlogCard: React.FC<{ children }> = ({ children }) => {
  return (
    <FeaturedCardContainer data-hs="blog-listing-featured">
      <Imagery>
        <a href="{{ featuredContent.absolute_url }}">
          <img alt="{{ featuredContent.featured_image_alt_text }}" src="{{ featuredContent.featured_image }}" />
        </a>
      </Imagery>

      <Content>
        <a href="{{ featuredContent.absolute_url }}">
          <h2>{`{{ featuredContent.name }}`}</h2>
        </a>
        <p>
          <span data-hs="blog-listing-topic">{`{{ topic.name }}`}</span>{' '}
          {`{{ featuredContent.publish_date|datetimeformat('%b %e, %Y') }}`}
        </p>
        <p>{`{{ featuredContent.post_list_content }}`}</p>
        <Button variation="default" color="purple" url="{{ featuredContent.absolute_url }}" label="Read more" />
      </Content>
    </FeaturedCardContainer>
  );
};

export default FeaturedBlogCard;
