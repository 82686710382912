import React from 'react';
import { graphql } from 'gatsby';
import styled, { keyframes, css } from 'styled-components';
import { up } from 'styled-breakpoints';
import Image from 'gatsby-image';
import LazyLoad from 'react-lazyload';
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';

import { Button, RichText } from '../';
import LogoFaceOnly from '../Icons/LogoFaceOnly';
import LogoStudio from '../Icons/LogoStudio';
import Chevron from '../Icons/Chevron';

import AnimatedShape from './AnimatedShape';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform:scaleY(0.5);
  }
  60% {
    opacity: 0;
    transform:scaleY(0.5);
  }
  100% {
    opacity: 1;
    transform:scaleY(1);
  }
`;

const ButtonArrow = styled(Chevron)`
  height: 20px;
  width: 12px;
  margin-right: 8px;
  margin-left: -8px;
  fill: white;
  ${up('md')} {
    margin-right: 8px;
    margin-left: -8px;
    fill: ${(props) => props.theme.colors.primary};
  }
`;

const HeroButton = styled(Button)`
  background: ${(props) => props.theme.colors.primary};
  color: white;
  font-size: 24px;
  font-weight: 600;
  padding: 12px 24px;
  display: inline-flex;
  align-items: center;
  position: relative;
  ${up('md')} {
    background: white;
    color: ${(props) => props.theme.colors.primary};
  }

  &:hover {
    color: ${(props) => props.theme.colors.primary};
    background: white;
    ${up('md')} {
      color: white;
      background: ${(props) => props.theme.colors.primary};
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      border: 1px solid ${(props) => props.theme.colors.primary};
      ${up('md')} {
        border: 1px solid white;
      }
    }

    svg {
      fill: ${(props) => props.theme.colors.primary};
      ${up('md')} {
        fill: white;
      }
    }
  }
`;

const Wrapper = styled.div`
  position relative;
  display: flex;
  justify-content: center;
  ${(props) => props.fullScreen && 'margin-bottom: -60px;'};
  ${up('md')} {
    ${(props) => !props.fullScreen && 'margin-bottom: 40px;'};
    ${(props) => props.fullScreen && 'margin-bottom: 0;'};
  }
`;

const HeroContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;

  ${up('md')} {
    height: ${(props) => (props.fullScreen ? `calc(${props.videoHeight}vh - 58px)` : '880px')};
    ${(props) => props.videoHeight === '50' && 'min-height: 470px;'}
    background-color: ${(props) => props.theme.colors.primary};
    transition: 0.3s margin ease;
    margin-bottom: ${(props) => (props.fullScreen ? '0' : '-90px')};
    ${(props) => !props.inView && props.fullScreen && 'margin-bottom: -110px;'}
  }
`;

const HeroInner = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  height: 100%;
  max-width: 1140px;
  padding: 30px 20px;

  ${up('lg')} {
    padding: 0 20px;
  }
`;

const ContentBlock = styled.div`
  width: 100%;

  p {
    font-size: 24px;
    margin-bottom: 2.4rem;
    ${up('lg')} {
      margin-bottom: 3rem;
    }
  }

  ${up('md')} {
    margin-top: 110px;
    transform-origin: top;
    animation-name: ${fadeIn};
    animation-duration: 1s;
    width: 50%;
    h1,
    h3,
    p {
      color: #fff;
    }
    ${(props) =>
      props.fullScreen &&
      `
      display: flex;
      width: 60%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin-top: 0;
      h1{
        font-size: 70px
      }
      p {
        color: #fff;
        font-size: 28px
      }
    `}
  }
`;

const VideoContainer = styled.div`
  display: none;
  ${up('md')} {
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 0;
    width: 100%;
    max-width: 1400px;
    margin-top: -70px;
  }
`;

const FullScreenVideoContainer = styled.div`
  display: none;
  ${up('md')} {
    display: block;
    width: 100%;
  }
`;

const getVideoStyles = (fullScreen) => {
  if (fullScreen) {
    return `
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
    `;
  } else {
    return `width: 1400px;
    min-width: 1400px;
    position: relative;`;
  }
};

const Overlay = styled.div`
  display: none;
  ${up('md')} {
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

const Video = styled.video`
  ${(props) => getVideoStyles(props.fullScreen)}
`;

const ImageContainer = styled.div`
  width: 100%;
  ${up('md')} {
    display: none;
  }
`;

const Logo = styled(LogoFaceOnly)`
  margin-bottom: 16px;
  width: 80px;
  height: 80px;
  display: none;
  ${up('md')} {
    display: block;
    margin-right: auto;
  }
`;

const StudioIcon = styled(LogoStudio)`
  margin-bottom: 16px;
  width: 80px;
  height: 80px;
  display: none;
  ${up('md')} {
    display: flex;
    margin-right: auto;
  }
`;

const ContentKnockout = styled.div`
  position: absolute;
  width: 100%;
  height: 300px;
  max-width: 1140px;
  background-color: white;
  transition: 0.3s bottom ease;
  bottom: ${(props) => (props.inView ? '-300px' : '-280px')};
`;

interface ButtonModel {
  url: string;
  label: string;
}

interface VideoImageModel {
  description: string;
  fluid: { GatsbyContentfulFluid };
}

interface VideoModel {
  file: {
    url: string;
    contentType: string;
  };
}

interface HeroProps {
  richTextBody: unknown;
  buttons: ButtonModel[];
  video: VideoModel[];
  videoImage: VideoImageModel;
  fullScreen: boolean;
  heroLogo?: string;
  videoHeight?: string;
}

const brandLogo = (name) => {
  const brand = name.toLowerCase();
  const brands = {
    studio: <StudioIcon />,
    sparkol: <Logo />,
  };

  return brands[brand];
};

const Hero: React.FC<HeroProps> = ({
  richTextBody,
  buttons,
  video,
  videoImage,
  fullScreen,
  heroLogo,
  videoHeight = '90%',
}) => {
  const imageUrl = videoImage ? videoImage.fluid.src : null;
  const { ref, inView } = useInView({
    threshold: 0.92,
    initialInView: true,
  });

  const videoVh = videoHeight.substring(0, videoHeight.length - 1);
  return (
    <Wrapper fullScreen={fullScreen}>
      <HeroContainer ref={ref} inView={inView} fullScreen={fullScreen} videoHeight={videoVh}>
        {imageUrl && (
          <ImageContainer>
            <LazyLoad once height="100%">
              <Image fluid={videoImage.fluid} alt={videoImage.description} />
            </LazyLoad>
          </ImageContainer>
        )}
        {fullScreen ? (
          <FullScreenVideoContainer>
            <Video fullScreen playsInline autoPlay muted loop>
              {video.map((item, index) => {
                return <source key={index} src={item.file.url} type={item.file.contentType} />;
              })}
            </Video>
            <Overlay />
          </FullScreenVideoContainer>
        ) : (
          <VideoContainer>
            <AnimatedShape />
            <LazyLoad once height="950px">
              <Video playsInline autoPlay muted>
                {video.map((item, index) => {
                  return <source key={index} src={item.file.url} type={item.file.contentType} />;
                })}
              </Video>
            </LazyLoad>
          </VideoContainer>
        )}
        <HeroInner>
          <ContentBlock fullScreen={fullScreen}>
            {heroLogo && brandLogo(heroLogo)}
            <RichText richTextBody={richTextBody} />
            {buttons &&
              buttons.map(({ label, url }, index) => {
                return <HeroButton key={index} left={<ButtonArrow />} label={label} url={url} />;
              })}
          </ContentBlock>
        </HeroInner>
      </HeroContainer>
      {fullScreen && <ContentKnockout inView={inView} />}
    </Wrapper>
  );
};

Hero.displayName = 'Hero';

export default Hero;

export const query = graphql`
  fragment HeroModel on ContentfulHero {
    video {
      file {
        url
        fileName
        contentType
      }
    }
    heroLogo
    fullScreen
    videoHeight
    videoImage {
      description
      fluid(maxWidth: 600) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    richTextBody {
      json
    }
    buttons {
      url
      label
    }
  }
`;
