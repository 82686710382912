import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { withAssetPrefix } from 'gatsby';

import { Button } from '../';

const Container = styled.div`
  width: 100%;
  ${up('md')} {
    padding-bottom: 80px;
  }
`;

const SocialLink = styled(Button)`
  width: 40px;
  height: 40px;
  margin-right: 12px;
  img {
    width: 100%;
    height: 100%;
  }
`;

const getSocialLogo = (name) => {
  const network = {
    facebook: withAssetPrefix('/facebook-icon.png'),
    twitter: withAssetPrefix('/twitter-icon.png'),
    youtube: withAssetPrefix('/youtube-icon.png'),
    instagram: withAssetPrefix('/instagram-icon.png'),
    linkedIn: withAssetPrefix('/linkedIn-icon.png'),
  };

  return network[name];
};

const getSocialLinks = (type) => {
  const name = type.toLowerCase();
  const types = {
    sparkol: [
      { network: 'facebook', url: 'https://www.facebook.com/SparkolHQ/' },
      { network: 'twitter', url: 'https://twitter.com/SparkolHQ' },
      { network: 'youtube', url: 'https://www.youtube.com/channel/UC-M3N1dBICkLMU8fTlmpgjw' },
      { network: 'instagram', url: 'https://www.instagram.com/sparkolhq/' },
      { network: 'linkedIn', url: 'https://www.linkedin.com/company/sparkol/' },
    ],
    scribely: [
      { network: 'facebook', url: 'https://www.facebook.com/scribely' },
      { network: 'twitter', url: 'https://twitter.com/ScribelyApp' },
      { network: 'youtube', url: 'https://www.youtube.com/channel/UCmyzmHPyp5Q0dVgLoz7j-sw' },
      { network: 'instagram', url: 'https://www.instagram.com/ScribelyApp/' },
      { network: 'linkedIn', url: 'https://www.linkedin.com/showcase/scribelyapp/' },
    ],
  };
  return types[name];
};

const SocialLinks: React.FC = ({ type }) => {
  const links = getSocialLinks(type);
  return (
    <Container>
      {links.map((link, index) => (
        <SocialLink key={index} url={link.url}>
          <img src={getSocialLogo(link.network)} alt={`${link.network} logo`} />
        </SocialLink>
      ))}
    </Container>
  );
};

export default SocialLinks;
