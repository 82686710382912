import React from 'react';
import { Helmet } from 'react-helmet';
import './cookieConsentBar.css';
import { useRef, useEffect, useReducer } from 'react';

//  From @jeetiss/react-use-script

const useLatestRef = (value) => {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
};

// eslint-disable-next-line object-property-newline
const merge = (state, newState) => {
  return { ...state, ...newState };
};
const runRef = (ref, args = []) => {
  return ref.current && ref.current(...args);
};

export const useScript = (url, delay = 0, { onLoad, onError, onComplete } = {}) => {
  const [{ loaded, error }, setState] = useReducer(merge, {
    loaded: false,
    error: null,
  });

  const savedLoad = useLatestRef(onLoad);
  const savedError = useLatestRef(onError);
  const savedComplete = useLatestRef(onComplete);

  useEffect(() => {
    setTimeout(() => {
      const script = document.createElement('script');

      script.src = url;
      script.async = 1;

      const loadedFine = () => {
        setState({ loaded: true });
        runRef(savedLoad);
        runRef(savedComplete);
      };

      const loadedWithError = (error) => {
        setState({ error });
        runRef(savedError, [error]);
        runRef(savedComplete);
      };

      script.addEventListener('load', loadedFine);
      script.addEventListener('error', loadedWithError);
      document.body.appendChild(script);

      return () => {
        script.removeEventListener('load', loadedFine);
        script.removeEventListener('error', loadedWithError);
        script.parentNode.removeChild(script);
      };
    }, delay);
  }, [savedComplete, savedError, savedLoad, url]);

  return [loaded, error];
};

const CookieConsentBar = () => {
  const [loaded, error] = useScript('https://cdn.websitepolicies.io/lib/cookieconsent/cookieconsent.min.js', 2000);

  useEffect(() => {
    if (loaded && !error) {
      // eslint-disable
      window.wpcc.init({
        border: 'thin',
        corners: 'small',
        colors: {
          popup: { background: '#27A8CB', text: '#fff', border: '#00c8f0' },
          button: { background: '#fffbfb', text: '#131515' },
        },
        position: 'bottom',
        content: { href: 'https://help.sparkol.com/support/solutions/articles/1000033748' },
      });
      // eslint-enable
    }
  }, [loaded, error]);

  return (
    <Helmet>
      <link rel="dns-prefetch" href="https://wpcc.io" />
    </Helmet>
  );
};

export default CookieConsentBar;
