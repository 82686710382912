import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import { Button } from '../';

const SubMenuLinks = styled.ul`
  display: ${(props) => (props.active ? 'block' : 'none')};
  border-bottom: ${(props) => (props.active ? '1px solid #7a7a79' : '0')};
  ${up('lg')} {
    position: absolute;
    border: 0;
    width: fit-content;
    box-shadow: 5px 7px 14px -1px rgba(0, 0, 0, 0.4);
  }
`;

const SubMenuItem = styled.li``;

const SubButton = styled(Button)`
  justify-content: left;
  font-size: 14px;
  color: ${(props) => (props.active ? props.theme.colors.primary : '#000')};
  padding: 12px 10px 12px 48px;
  width: 100%;
  background: ${(props) => (props.active ? '#ddd' : '#f4f4f4')};
  ${up('lg')} {
    border-top: 1px solid #e8e8e8;
    background: white;
    padding: 14px 40px;
    &:hover {
      background-color: #f4f4f4;
    }
  }
`;

type LinkModel = {
  id: string;
  label: string;
  slug: string;
};

interface SubMenuProps {
  links?: LinkModel[];
  activePage: string | null;
  activeSubmenu: boolean;
}

const SubMenu: React.FC<SubMenuProps> = ({ links, activePage, activeSubmenu }) => {
  return (
    <SubMenuLinks active={activeSubmenu}>
      {links.map((link, index) => {
        const isActive = link.slug === activePage;
        return (
          <SubMenuItem key={index}>
            <SubButton role={'menuitem'} url={link.slug} label={link.label} active={isActive || undefined} />
          </SubMenuItem>
        );
      })}
    </SubMenuLinks>
  );
};

export default SubMenu;
