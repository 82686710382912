import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset-advanced';
import { up } from 'styled-breakpoints';

const GlobalStyle = createGlobalStyle`
  ${reset};

  :focus { 
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
 
  body {
    font-family: 'Silka', 'Arial', 'Helvetica', sans-serif;
    font-weight: normal;
  }

  h1, h2, h3, h4, h5, h6 {
    display: block;
    line-height: 1.2;
    margin-bottom: 1.2rem;
  } 

  h1{
    font-family: 'Chaney','Arial', 'Helvetica', sans-serif;
  }

  h2, h3, h4, h5, h6 {
    font-family: 'GT Walsheim','Arial', 'Helvetica', sans-serif;
    margin-bottom: 1.8rem;
  }

  h1{
    font-size: 40px;
    margin-bottom: 1.2rem;
    ${up('lg')} {
      font-size: 48px;
      margin-bottom: 1.5rem;
    }
  }
  h2{font-size: 32px}
  h3{font-size: 22px}
  h4{font-size: 18px}
  h5{font-size: 16px}
  h6{font-size: 12px}

  p{
    font-size: 16px; 
    line-height: 1.5;
    margin-bottom: 2rem;

    a{
      text-decoration: underline ${(props) => props.theme.colors.primary};
      &:hover {
        text-decoration: none;
      }
    }
  }

  strong, b{
    font-weight: bold;
  }
`;

export default GlobalStyle;
