import React from 'react';
import styled from 'styled-components';

const Chevron = styled.svg`
  fill: ${(props) => props.color};
`;

interface ChevronProps {
  fillColor?: string;
  className?: string;
}

const ChevronIcon: React.FC<ChevronProps> = ({ fillColor, className }) => {
  return (
    <Chevron
      className={className}
      enableBackground="new 0 0 10 18"
      viewBox="0 0 10 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m1.15 0-1.15 1.17 7.699 7.83-7.699 7.83 1.15 1.17 8.85-9z" />
    </Chevron>
  );
};

export default ChevronIcon;
