import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import Image from 'gatsby-image';

import Shape from './Shape';

const Hero = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 300px;
  overflow: hidden;
  background-color: ${(props) => props.bgColor};
  margin-bottom: 10px;
  ${up('md')} {
    height: 460px;
    margin-bottom: -30px;
  }
`;

const Inner = styled.div`
  width: 100%;
  max-width: 1140px;
  padding: 0 20px;
  display: flex;
  position: relative;
`;

const ImageBox = styled.div`
  max-height: 100%;
  padding-top: 25px;
  margin-left: -15px;
  width: 230px;
  ${up('md')} {
    width: 320px;
    margin-left: 90px;
  }
`;

const Row = styled.div`
  display: none;
  width: 100%;
  padding: 0 20px;
  background-color: #fff;
  position: absolute;
  justify-content: center;
  bottom: 0;
  left: 0;
  ${up('md')} {
    display: inline-flex;
  }
`;

const Heading = styled.h1`
  width: 100%;
  max-width: 880px;
  padding: 20px;
  font-family: 'GT Walsheim', 'Arial', 'Helvetica', sans-serif;
  margin: 0;
  ${up('md')} {
    padding: 40px 0 0 0;
  }
`;

const MobileOnly = styled.div`
  display: flex;
  margin-bottom: -60px;
  ${up('md')} {
    display: none;
  }
`;

const getColor = (color) => {
  const colors = ['#debae6', '#ff3377', '#f2e376', '#bfe8bf'];
  return colors[color];
};

interface HeroProps {
  heroColor: string;
  image: unknown;
}

const HeroSub: React.FC<HeroProps> = ({ heroColor, image, title }) => {
  const background = getColor(heroColor);
  return (
    <>
      <Hero bgColor={background}>
        <Inner>
          <Shape />
          <ImageBox>
            <Image fluid={image.fluid} alt={image.description} />
          </ImageBox>
          <Row>
            <Heading>{title}</Heading>
          </Row>
        </Inner>
      </Hero>
      <MobileOnly>
        <Heading>{title}</Heading>
      </MobileOnly>
    </>
  );
};

HeroSub.displayName = 'HeroSubPage';

export default HeroSub;

export const query = graphql`
  fragment HeroSub on ContentfulSubPageHero {
    title
    heroColor
    image {
      description
      fluid(maxWidth: 330) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`;

