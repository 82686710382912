import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Chevron from '../Icons/Chevron';

import LargeCard from './LargeCard';
import MediumCard from './MediumCard';
import SmallCard from './SmallCard';

const ButtonArrow = styled(Chevron)`
  height: 16px;
  width: 10px;
  margin-right: 10px;
  margin-left: 0;
  fill: white;
`;

const getCardType = (cardCount) => {
  let card = LargeCard;

  if (cardCount === 2) {
    card = MediumCard;
  }

  if (cardCount > 2) {
    card = SmallCard;
  }

  return card;
};

type ButtonModel = {
  label: string;
  url: string;
};
interface CardProps {
  richTextBody: unknown;
  image: unknown;
  colourPalette?: number;
  alignment?: 'left' | 'right';
  buttons?: ButtonModel[];
  sectionComponentCount?: number;
  variation?: string;
  imagerySize?: string;
  buttonIcon?: React.FC;
  videoComponent?: unknown;
  hoverImage: unknown;
}

const Card: React.FC<CardProps> = ({
  richTextBody,
  image,
  colourPalette = 0,
  buttons,
  alignment = 'right',
  sectionComponentCount,
  imagerySize = 'medium',
  videoComponent,
  hoverImage,
}) => {
  const CardComponentType = getCardType(sectionComponentCount);
  return (
    <CardComponentType
      richTextBody={richTextBody}
      image={image}
      colourPalette={colourPalette ? colourPalette : 0}
      buttons={buttons}
      alignment={alignment}
      buttonIcon={<ButtonArrow />}
      imagerySize={imagerySize}
      video={videoComponent}
      hoverImage={hoverImage}
    />
  );
};

Card.displayName = 'Card';

export default Card;

export const query = graphql`
  fragment Card on ContentfulCard {
    title
    alignment
    colourPalette
    richTextBody {
      json
    }
    image {
      description
      fluid(maxWidth: 510) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    hoverImage {
      description
      fluid(maxWidth: 510) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    imagerySize
    buttons {
      url
      label
    }
    videoComponent: video {
      url: videoUrl
      autoPlay
      loop
      showControls
    }
  }
`;
