import React from 'react';
import styled, { keyframes } from 'styled-components';

const reveal = keyframes`
  0% {
    fill: transparent;
    transform: rotate(-360deg)
  }

  50% {
    fill: transparent;
  }

  100% {
    fill: rgba(255, 255, 255, 0.4);
    transform: rotate(0deg)
  }
`;

const SemiCircle = styled.div`
  svg {
    width: 1100px;
    min-width: 930px;
    position: absolute;
    left: 40%;
    top: 200px;
    fill: rgba(255, 255, 255, 0.4);
    transform-origin: top left;
    animation-name: ${reveal};
    animation-duration: 3s;
  }
`;

const AnimatedShape: React.FC = () => {
  return (
    <SemiCircle>
      <svg viewBox="0 0 1214.25 766.54">
        <path
          d="m1248.28 152.21c-174.46 499.65-719.14 765.56-1214.28 592.71l121.1-346.83c303.9 106.08 638.66-58.54 746.33-366.95z"
          transform="translate(-34.03 -31.14)"
        />
      </svg>
    </SemiCircle>
  );
};

export default AnimatedShape;
