import React from 'react';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';

import Chevron from '../Icons/Chevron';

const ButtonArrow = styled(Chevron)`
  height: 16px;
  width: 10px;
  margin-right: 10px;
  margin-left: 0;
  fill: white;
`;

const getButtonStyles = (props) => {
  if (props.variation === 'block') {
    props.variation = 'default';
  }
  const variations = {
    default: `
    justify-content: center;
    padding: 18px 24px;
    margin-right: 20px;
    margin-bottom: 15px;
    ${props.color && props.theme.buttons.variation[props.color]}
    &:hover {
      &:after {
        content: '';
        border: 1px solid;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    `,
    fluid: `
    display: inline-flew;
    flex-direction: row;
    padding: 18px 8px;
    display: flex;
    margin: 0;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    ${props.color && props.theme.buttons.variation[props.color]}
    ${up('md')} {
      width: 50%;
      padding: 40px 0 0 0;
      margin: 0 30px 0px 0;
      transition: 250ms ease-in;
    }
    &:hover {
      &:after {
        content: '';
        border: 1px solid;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    `,
    blank: ``,
  };

  return variations[props.variation];
};

const commonStyles = css`
  position: relative;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
  ${up('md')} {
    justify-content: center;
  }
`;

const StyledButton = styled.button`
  ${commonStyles};
  ${(props) => getButtonStyles(props)};
`;

const StyledAnchor = styled.a`
  ${commonStyles};
  ${(props) => getButtonStyles(props)};
`;

interface ButtonProps {
  label?: string;
  url?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  left?: React.FC | false;
  'aria-haspopup'?: boolean;
  'aria-expanded'?: boolean;
  variation?: 'default' | 'blank' | 'fluid' | 'block';
  color?: 'default' | 'purple' | 'yellow' | 'green';
}

const Button: React.FC<ButtonProps> = ({ label, url, left, color, variation = 'blank', ...rest }) => {
  const isButton = url === '' || url === null;
  const ButtonType = isButton ? StyledButton : StyledAnchor;

  let buttonIcon = variation === 'default' || variation === 'fluid' ? <ButtonArrow /> : left;
  buttonIcon = variation === 'block' ? false : buttonIcon;
  const props = {};

  props.variation = variation;

  if (color) {
    props.color = color;
  }

  if (isButton) {
    props.type = 'button';
  } else {
    props.href = url;
  }

  return (
    <ButtonType {...props} {...rest}>
      {buttonIcon}
      {label}
      {rest?.children}
    </ButtonType>
  );
};

export default Button;
