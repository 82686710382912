import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import { Button } from '../';
import Chevron from '../Icons/Chevron';

import SubMenu from './SubMenu';

const NavItem = styled.li`
  a:focus,
  button:focus {
    outline: 2px solid ${(props) => props.theme.colors.primary};
    outline-offset: -4px;
  }
`;

const TopButton = styled(Button).attrs(({ tags }) => ({
  ...tags,
}))`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #1d1d1b;
  padding: 12px 12px 12px 48px;
  width: 100%;
  background: ${(props) => (props.active ? '#d1d1d1' : '#e8e8e8')};
  border-bottom: 1px solid #7a7a79;
  white-space: nowrap;
  justify-content: end;
  ${up('lg')} {
    padding: 12px 17px;
    text-align: center;
    height: 100%;
    border: 0;
    background: ${(props) => (props.active ? '#f4f4f4' : 'white')};
    &:hover {
      background-color: #f4f4f4;
    }
  }
`;

const ButtonArrow = styled(Chevron)`
  height: 12px;
  width: 12px;
  margin-right: 16px;
  margin-left: -27px;
  transition: 200ms ease-in-out;
  ${(props) => props.active && 'transform: rotate(90deg)'};
  ${up('lg')} {
    margin-right: 8px;
    margin-left: 0;
    ${(props) => (props.active ? 'transform: rotate(-90deg)' : 'transform: rotate(90deg)')};
  }
`;

const checkActiveState = (link, activePage) => {
  const topLinkActive = link.slug === activePage;
  const subLevelActive = !!link.subNavItems && link.subNavItems.some((item) => item.slug === activePage);
  return topLinkActive || subLevelActive;
};

type LinkModel = {
  id: string;
  label: string;
  slug: string | null;
  subNavItems?: LinkModel[];
};
interface TopLevelLinkProps {
  id: number;
  link: LinkModel;
  activePage: string;
  setActiveDropDown: unknown;
  activeDropDown: number | null;
}

const TopLevelLink: React.FC<TopLevelLinkProps> = ({ id, link, activePage, setActiveDropDown, activeDropDown }) => {
  const isActive = checkActiveState(link, activePage);
  const activeSubmenu = id === activeDropDown;

  const dropDownProps = {};
  dropDownProps.role = 'menuitem';
  if (link.subNavItems) {
    dropDownProps['aria-haspopup'] = true;
    dropDownProps['aria-expanded'] = activeSubmenu;
    dropDownProps.left = <ButtonArrow active={activeSubmenu} />;
  }

  return (
    <NavItem role="none">
      <TopButton
        {...dropDownProps}
        url={link.slug}
        label={link.label}
        active={isActive ? isActive.toString() : undefined}
        onClick={() => (activeDropDown === id ? setActiveDropDown(null) : setActiveDropDown(id))}
      />
      {!!link.subNavItems && <SubMenu links={link.subNavItems} activePage={activePage} activeSubmenu={activeSubmenu} />}
    </NavItem>
  );
};

export default TopLevelLink;
