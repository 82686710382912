import React from 'react';
import styled from 'styled-components';

const Logo = styled.svg`
  fill: ${(props) => props.fillColor};
`;

interface LogoProps {
  fillColor?: string;
  className?: string;
}

const LogoFaceOnly: React.FC<LogoProps> = ({ fillColor = 'white', className }) => {
  return (
    <Logo viewBox="0 0 100 109" fillColor={fillColor} className={className}>
      <g>
        <path d="m31.546 34.769h-11.316c-.031-2.455-2.052-4.421-4.513-4.39-2.418.03-4.371 1.978-4.401 4.39h-11.316c.058-8.689 7.167-15.686 15.878-15.628 8.63.057 15.611 7.021 15.668 15.628z" />
        <path d="m89.813 34.769h-11.316c0-2.455-1.996-4.446-4.457-4.446-2.462 0-4.457 1.991-4.457 4.446h-11.317c.006-8.689 7.073-15.728 15.784-15.722 8.703.005 15.757 7.041 15.763 15.722z" />
        <path d="m62.309 71.559-13.192-13.016c-13.474-13.279-13.604-34.939-.29-48.379 13.241-13.365 34.812-13.577 48.315-.473l2.858 2.825-7.969 8.014-2.812-2.782c-4.274-4.133-9.998-6.437-15.95-6.421h-.095c-12.692.051-22.941 10.354-22.89 23.014.024 6.08 2.469 11.901 6.797 16.182l13.188 13.011z" />
        <path d="m44.766 109c-9.044.03-17.735-3.502-24.183-9.828-6.497-6.305-10.17-14.957-10.188-23.998l11.317-.045c.015 6.024 2.465 11.788 6.797 15.986 4.337 4.25 10.18 6.622 16.26 6.599h.098l.044 11.286z" />
      </g>
    </Logo>
  );
};

export default LogoFaceOnly;
