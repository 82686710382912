import React from 'react';
import styled from 'styled-components';

const Logo = styled.svg`
  fill: ${(props) => props.fillColor};
`;

interface LogoProps {
  className?: string;
  type?: string;
  fillColor?: string;
}

const LogoStudio: React.FC<LogoProps> = ({ type, className, fillColor = 'white' }) => {
  if (type === 'studio') {
    return (
      <svg width="200px" height="59px" enableBackground="new 0 0 482 140" viewBox="0 0 482 140">
        <path d="m416.1 45.2c-5.9-2.4-12-4.2-18.2-5.3l9.9-34.1 18.2 5.3z" fill="#f37" />
        <path d="m448.1 92.4c-.9-6.3-2.4-12.5-4.5-18.5l33.9-8.4 4.5 18.5z" fill="#f37" />
        <path d="m438.4 64.5c-3.9-5-8.3-9.6-13.1-13.7l25.2-24.3 13.1 13.7z" fill="#f37" />
        <g fill="#1d1d1b">
          <path d="m391.3 139.8c-23.8 0-43.1-19.4-43.1-43.2 0-23.9 19.3-43.2 43.1-43.2s43.1 19.4 43.1 43.2c0 23.9-19.3 43.2-43.1 43.2zm0-67.4c-13.3 0-24.1 10.8-24.1 24.2s10.8 24.2 24.1 24.2 24.1-10.8 24.1-24.2c0-13.3-10.8-24.2-24.1-24.2z" />
          <path d="m41.6 140c-10.6 0-20.8-4-28.5-11.3-8.3-8-13.1-19-13.1-30.5v-1h20v1c0 12 9.7 21.8 21.7 21.8h1.4c10.9-.8 19.6-9.6 20.2-20.6 0-.4 0-.8 0-1.1 0-7.8-5.4-14.9-13.2-17.2l-24-7.3c-14.4-4.5-24.2-17.8-24-32.5 0-.7 0-1.4.1-2.1 1.5-19.9 18.8-34.8 38.7-33.4 18.8 1.5 33.4 17.2 33.4 36.1h-20c0-4.6-2-9-5.4-12.1s-8-4.6-12.6-4c-7.7 1-13.6 7.2-14.2 14.9v1c.1 6 4.1 11.2 9.8 12.8l24 7.3c16.1 4.9 27.4 19.9 27.3 36.5 0 .7 0 1.4-.1 2.1-1.1 21.2-17.8 38.2-38.9 39.5-.8.1-1.7.1-2.6.1z" />
          <path d="m92.1 32.5h20.5v22.8h22.9v16.3h-22.9v38c0 6.9 3.5 11.3 11.4 11.3h11v17h-15.3c-17.2 0-27.6-9.7-27.6-27.5z" />
          <path d="m144.3 109.3v-54h20.5v48.8c0 10.1 6.6 17 16.1 17 9.9 0 17-8 17-18.4v-47.4h20.5v82.6h-19.6v-10h-1.3c-3.3 6.1-11.5 12.1-23.2 12.1-18.5 0-30-13.1-30-30.7z" />
          <path d="m225.7 97.1c0-25.9 15.8-43.8 38.5-43.8 14.4 0 22.4 8.5 25.3 12.7h1.1v-45.9h20.5v117.8h-20.2v-10.5h-1.1c-2.4 3.5-9.8 12.6-24.9 12.6-23.3 0-39.2-17.3-39.2-42.9zm65.3-.3c0-15.5-9.5-25-22.4-25-13.1 0-22.1 10.4-22.1 25 0 14.9 9 24.8 22.3 24.8 13.7 0 22.2-11 22.2-24.8z" />
          <path d="m320.8 55.3h20.5v82.6h-20.5z" />
        </g>
        <g fill="#f37">
          <path d="m279.1 32.5c-2.7 0-4.8-2.2-4.8-4.8v-21.9h-6v21.9c0 6 4.8 10.8 10.8 10.8h.4v-6z" />
          <path d="m161 13.2c-2.2 0-4.4.7-6.2 2.1l-.3.3v-1.8h-5.6v33.8h5.7v-11.5l.3.3c1.8 1.4 4.1 2.2 6.3 2.1 6.8 0 11.8-5.3 11.8-12.7.1-3.3-1.1-6.5-3.3-9-2.3-2.4-5.4-3.7-8.7-3.6zm-.1 19.8c-3.9 0-6.4-2.8-6.4-7.2 0-3.5 2-7.2 6.3-7.2 3.9 0 6.4 2.8 6.4 7.2.1 4.3-2.5 7.2-6.3 7.2z" />
          <path d="m175.6 25.9c-.1 3.3 1.1 6.5 3.3 9 2.3 2.3 5.4 3.6 8.7 3.6 2.2 0 4.4-.7 6.2-2.1l.3-.3v1.8h5.6v-24.2h-5.7v1.8l-.3-.3c-1.8-1.4-4.1-2.2-6.3-2.1-6.9.1-11.8 5.4-11.8 12.8zm18.6 0c0 3.5-2 7.2-6.3 7.2-3.9 0-6.4-2.8-6.4-7.2 0-4.3 2.5-7.2 6.3-7.2 3.8-.1 6.4 2.8 6.4 7.2z" />
          <path d="m209.4 15.3-.3.3v-1.8h-5.7v24.2h5.7v-12.5c0-2.9 1.3-6 5-6h3v-5.7h-3c-1.7-.1-3.4.5-4.7 1.5z" />
          <path d="m241.6 13.8h-7.5l-7.8 8.6v-16.6h-5.7v32.1h5.7v-7.7l2.3-2.4 6.4 10.1h6.6l-9.1-14.3z" />
          <path d="m252.9 13.2c-6.8-.1-12.4 5.3-12.5 12.1v.6c-.1 3.4 1.2 6.6 3.6 9 2.4 2.3 5.6 3.6 8.9 3.6 7 0 12.6-5.6 12.6-12.6s-5.6-12.7-12.6-12.7zm0 19.9c-4 0-6.7-3-6.7-7.2s2.8-7.2 6.7-7.2c4 0 6.8 3 6.8 7.2s-2.8 7.2-6.8 7.2z" />
          <path d="m134 38.4c-3 0-6-1.2-8.2-3.2-2.4-2.3-3.8-5.4-3.8-8.7v-.3h5.7v.3c0 3.4 2.8 6.2 6.2 6.2h.4c3.1-.2 5.6-2.7 5.8-5.9 0-.1 0-.2 0-.3-.1-2.3-1.6-4.3-3.8-4.9l-6.9-2.1c-4.1-1.2-6.9-5-6.9-9.2 0-.2 0-.4 0-.6.4-5.7 5.4-10 11.1-9.6 5.4.4 9.6 4.9 9.6 10.3h-5.7c0-2.6-2.1-4.6-4.6-4.6-2.4 0-4.4 1.9-4.6 4.3v.3c0 1.7 1.2 3.2 2.8 3.7l6.9 2.1c4.6 1.4 7.8 5.6 7.9 10.4v.6c-.3 6.1-5.1 10.9-11.2 11.3-.2-.1-.4-.1-.7-.1z" />
        </g>
        <ellipse cx="331.1" cy="34.2" fill="#1d1d1b" rx="12.7" ry="12.7" />
      </svg>
    );
  } else {
    return (
      <Logo viewBox="0 0 133.8 134.2" className={className} fillColor={fillColor}>
        <g>
          <path d="m67.9 39.4c-5.9-2.4-12-4.2-18.2-5.3l9.9-34.1 18.2 5.3z" />
          <path d="m99.9 86.6c-.9-6.3-2.4-12.5-4.5-18.5l33.9-8.4 4.5 18.5z" />
          <path d="m90.2 58.7c-3.9-5-8.3-9.6-13.1-13.7l25.2-24.3 13.1 13.7z" />
          <path d="m43.1 134c-23.8 0-43.1-19.4-43.1-43.2 0-23.9 19.3-43.2 43.1-43.2s43.1 19.4 43.1 43.2c0 23.9-19.3 43.2-43.1 43.2zm0-67.4c-13.3 0-24.1 10.8-24.1 24.2s10.8 24.2 24.1 24.2 24.1-10.8 24.1-24.2c0-13.3-10.8-24.2-24.1-24.2z" />
        </g>
      </Logo>
    );
  }
};

export default LogoStudio;
