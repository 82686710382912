import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image/withIEPolyfill';
import 'intersection-observer';
import { up } from 'styled-breakpoints';
import { useInView } from 'react-intersection-observer';

import { Button, RichText, Video, Section } from '../';

const getColor = (value) => {
  const colors = ['default', 'green', 'blue', 'purple'];
  return colors[value];
};

const BodyText = styled.div`
  width: 100%;
  order: 2;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  p:first-child {
    margin-bottom: 4px;
  }
`;

const BodyImage = styled.div`
  width: 100%;
  order: 1;
  margin-bottom: 30px;
  overflow: hidden;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: auto;
  a,
  button {
    flex-grow: 1;
    &:nth-child(1) {
      margin-right: 1px;
    }
    &:nth-child(2) {
      &:before {
        content: '';
        height: 100%;
        width: 1px;
        background: white;
        margin-left: -1px;
        position: absolute;
        left: 0;
      }
    }
  }
`;

const CardContainer = styled.div`
  display: flex;
  background-color: #fff;
  align-items: center;
  flex-direction: column;
  width: calc(50% - 20px);
  margin: 0 20px 60px 20px;
  text-align: center;
  :nth-child(2n + 1) {
    margin-left: 0;
  }
  :nth-child(2n) {
    margin-right: 0;
  }
  ${up('md')} {
    margin: 0 20px 80px 20px;
    transition: 250ms ease-in;
    transform: ${(props) => (props.inView ? 'translateY(0)' : 'translateY(200px)')};
  }

  ${up('lg')} {
    width: calc(25% - 60px);
    margin: 0 40px 80px 40px;
    :nth-child(2n + 1) {
      margin-left: 40px;
    }
    :nth-child(2n) {
      margin-right: 40px;
    }
    :nth-child(4n + 1) {
      margin-left: 0;
    }
    :nth-child(4n) {
      margin-right: 0;
    }
  }

  @media screen and (prefers-reduced-motion: reduce) {
    transform: none;
  }
`;

type ButtonModel = {
  label: string;
  url: string;
};
interface CardProps {
  richTextBody: unknown;
  image: unknown;
  colourPalette?: number;
  alignment?: 'left' | 'right';
  buttons?: ButtonModel[];
  variation?: string;
  buttonIcon?: unknown;
  video?: unknown;
}

const SmallCard: React.FC<CardProps> = ({
  richTextBody,
  image,
  colourPalette = 0,
  buttons,
  alignment = 'right',
  video,
}) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const buttonColor = getColor(colourPalette);

  return (
    <CardContainer ref={ref} alignment={alignment} variation={colourPalette} inView={inView}>
      <BodyText>
        <RichText richTextBody={richTextBody} />
        <ButtonsContainer>
          {buttons?.map((button, index) => (
            <Button key={index} label={button.label} url={button.url} color={buttonColor} variation="fluid" />
          ))}
        </ButtonsContainer>
      </BodyText>
      <BodyImage>
        {video ? (
          <Video url={video.url} autoPlay={video.autoPlay} loop={video.loop} showControls />
        ) : (
          <Image imgStyle={{ objectFit: 'contain' }} fluid={image.fluid} alt={image.description} />
        )}
      </BodyImage>
    </CardContainer>
  );
};

export default SmallCard;
