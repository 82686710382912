import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

const BlogCardContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;

  ${up('md')} {
    width: calc(50% - 20px);
    margin: 0 20px;
    margin-bottom: 80px;
    :nth-child(2n + 1) {
      margin-left: 0;
    }
    :nth-child(2n) {
      margin-right: 0;
    }
  ${up('lg')} {
    width: calc(33.33333% - 30px);
    :nth-child(2n + 1) {
      margin-left: 20px;
    }
    :nth-child(2n) {
      margin-right: 20px;
    }
    :nth-child(3n + 1) {
      margin-left: 0;
    }
    :nth-child(3n) {
      margin-right: 0;
    }
  }

`;

const Imagery = styled.div`
  width: 100%;
  align-self: end;
  margin-bottom: 24px;
  img {
    max-width: 100%;
  }
`;

const PostImage = styled.span`
  display: flex;
  width: 100%;
  height: 330px;
  background-position: center center;
  background-size: cover;
  ${up('md')} {
    height: 250px;
  }
  ${up('lg')} {
    height: 200px;
  }
`;

const Content = styled.div`
  width: 100%;
`;

const BlogCard: React.FC<{ children; className }> = ({ children, className }) => {
  return (
    <BlogCardContainer>
      <Imagery>
        <a href="{{ content.absolute_url }}">
          <PostImage
            style={{
              backgroundImage: 'url({{ content.featured_image }})',
            }}
          />
        </a>
      </Imagery>

      <Content>
        <a href="{{ content.absolute_url }}">
          <h3>{`{{ content.name }}`}</h3>
        </a>
        <p>
          <span data-hs="blog-listing-topic">{`{{ topic.name }}`}</span>{' '}
          {`{{ content.publish_date|datetimeformat('%b %e, %Y') }}`}
        </p>
      </Content>
    </BlogCardContainer>
  );
};

export default BlogCard;
