import React, { useState } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import OutsideClickHandler from 'react-outside-click-handler';

import LogoFull from '../Icons/LogoFull';

import TopLevelLink from './TopLevelLink';
import HamburgerMenuButton from './HamburgerMenuButton';

const MainNav = styled.nav`
  display: flex;
  justify-content: center;
  a:focus,
  button:focus {
    outline: 1px solid ${(props) => props.theme.colors.primary};
    outline-offset: -4px;
  }
`;

const Row = styled.div`
  height: 70px;
  display: flex;
  width: 100%;
  max-width: 1140px;
  align-items: center;
  flex-direction: column;
  ${up('lg')} {
    flex-direction: row;
    padding: 0 20px;
    height: 58px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  position: ${(props) => (props.navOpen ? 'fixed' : 'relative')};
  background: white;
  z-index: 5;
  align-items: center;
  width: 100%;
  height: 70px;
  ${up('lg')} {
    width: auto;
    height: auto;
    margin-right: auto;
  }
`;

const LogoLink = styled.a`
  ${up('lg')} {
    margin-left: -14px;
  }
`;

const Logo = styled(LogoFull)`
  width: 140px;
  height: 67px;
  fill: ${(props) => props.theme.colors.primary};
  ${up('lg')} {
    width: 120px;
    height: 55px;
  }
`;

const NavItems = styled.ul`
  position: fixed;
  transition: 400ms ease-in-out;
  transform: ${(props) => (props.show ? 'translateX(0)' : 'translateX(100%)')};
  top: 70px;
  z-index: 5;
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  background: #e8e8e8;
  ${up('lg')} {
    transform: none;
    top: auto;
    position: relative;
    height: 100%;
    width: auto;
    flex-direction: row;
    background: white;
  }
`;

const SkipLink = styled.a`
  font-size: 12px;
  position: absolute;
  display: block;
  white-space: nowrap;
  transform: translate(0, -150px);
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 3px;
  &:focus {
    transform: translate(130px, 0);
  }
`;

type NavItemsModel = {
  id: string;
  label: string;
  slug: string | null;
  subNavItems?: NavItemsModel[];
};

interface NavProps {
  navItems?: NavItemsModel[];
  activePage: string;
}

const MainNavigation: React.FC<NavProps> = ({ navItems, activePage }) => {
  const [activeDropDown, setActiveDropDown] = useState(null);
  const [showNav, setShowNav] = useState(false);
  return (
    <MainNav>
      <Row>
        <Wrapper navOpen={showNav}>
          <SkipLink href="#main">Skip to content</SkipLink>
          <LogoLink title="Sparkol homepage" href="/en/">
            <Logo />
          </LogoLink>
          <HamburgerMenuButton showNav={showNav} setShowNav={setShowNav} />
        </Wrapper>
        <OutsideClickHandler
          display="contents"
          onOutsideClick={() => {
            setActiveDropDown(null);
          }}
        >
          <NavItems show={showNav} role="menubar" id="navigation">
            {navItems.map((item, index) => (
              <TopLevelLink
                key={index}
                id={index}
                link={item}
                activePage={activePage}
                activeDropDown={activeDropDown}
                setActiveDropDown={setActiveDropDown}
              />
            ))}
          </NavItems>
        </OutsideClickHandler>
      </Row>
    </MainNav>
  );
};

MainNavigation.displayName = 'MainNavigation';

export default MainNavigation;
