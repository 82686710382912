import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import HubspotForm from './hubspotForm';

const Container = styled.div`
  width: 100%;
  iframe {
    max-width: 100%;
  }
`;

interface WidgetProps {
  widget: string;
  data: unknown;
}

const getWidget = (type) => {
  const widgets = {
    'Hubspot form': HubspotForm,
  };
  return widgets[type];
};

const Widget: React.FC<WidgetProps> = ({ widget, data }) => {
  const Widget = getWidget(widget);
  return (
    <Container>
      <Widget {...data} />
    </Container>
  );
};

export const query = graphql`
  fragment Widget on ContentfulWidget {
    widget
    data {
      formId
      portalId
    }
  }
`;

export default Widget;
