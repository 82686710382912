import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useScript } from '@jeetiss/react-use-script';

const libScriptHost = '//js.hsforms.net';

const HubspotSkeletonLoader = () => (
  <SkeletonTheme>
    <Skeleton height={40} count={5} />
  </SkeletonTheme>
);

interface HubSpotFormProps {
  portalId: string;
  formId: string;
}

const HubSpotForm: React.FC<HubSpotFormProps> = ({ portalId, formId }) => {
  const [loaded, error] = useScript(`${libScriptHost}/forms/v2.js`, 2000);
  const domId = `form-${formId.replace(/-/g, '')}`;

  useEffect(() => {
    if (loaded && !error) {
      /* eslint-disable no-undef */
      hbspt.forms.create({
        /* eslint-enable no-undef */
        portalId,
        formId,
        target: `#${domId}`,
      });
    }
  }, [loaded, error]);

  return (
    <>
      <Helmet>
        <link rel="dns-prefetch" href={libScriptHost} />
      </Helmet>
      {loaded ? <div id={domId}></div> : <HubspotSkeletonLoader />}
    </>
  );
};

export default HubSpotForm;
