import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

const Hamburger = styled.div`
  margin-left: auto;
  height: 100%;
  padding: 10px 15px;
  background: ${(props) => (!props.isOpen ? '#e8e8e8' : 'white')};
  ${up('lg')} {
    display: none;
`;

const HamburgerButton = styled.button`
  padding: 10px;
`;

const HamburgerBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 38px;
  width: 38px;
  position: relative;
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: ${(props) => (!props.isOpen ? 'rotate(45deg)' : 'rotate(0deg)')};
  span {
    position: absolute;
    display: block;
    top: 50%;
    width: 100%;
    height: 4px;
    margin-top: -2px;
    border-radius: 4px;
    background: ${(props) => props.theme.colors.primary};
    &:after,
    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background: ${(props) => props.theme.colors.primary};
    }

    &:before {
      ${(props) => (props.isOpen ? `top: -10px;` : `top: 0px; transform: rotate(90deg);`)}
      transition: top 75ms ease, transform 75ms cubic-bezier(.215,.61,.355,1) .12s
    }
    &:after {
      ${(props) => (props.isOpen ? `bottom: -10px;` : `bottom: 0px;`)}
      transition: bottom 75ms ease;
    }
  }
`;

interface HamburgerProps {
  showNav: boolean;
  setShowNav: unknown;
}

const HamburgerMenuButton: React.FC<HamburgerProps> = ({ showNav, setShowNav }) => {
  return (
    <Hamburger isOpen={!showNav}>
      <HamburgerButton aria-label="Menu" aria-controls="navigation" type="button" onClick={() => setShowNav(!showNav)}>
        <HamburgerBox isOpen={!showNav}>
          <span />
        </HamburgerBox>
      </HamburgerButton>
    </Hamburger>
  );
};

export default HamburgerMenuButton;
