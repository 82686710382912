import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import LogoFull from '../Icons/LogoFull';

import NavGroup from './NavGroup';

const Logo = styled(LogoFull)`
  width: 140px;
  fill: ${(props) => props.theme.colors.black};
  ${up('lg')} {
    width: 120px;
  }
`;

const FooterHeading = styled.h2`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const FooterNavGroup = styled(NavGroup)`
  order: 2;
  margin-bottom: 30px;
  width: 100%;
  ${up('lg')} {
    margin-top: 12px;
    margin-bottom: 0;
    width: auto;
    order: 1;
    width: auto;
    margin-right: 70px;
    :nth-child(5) {
      ${up('lg')} {
        margin-right: 10px;
      }
    }
  }
`;

const Wrapper = styled.footer`
  display: flex;
  justify-content: center;
  a:focus,
  button:focus {
    outline: 2px solid ${(props) => props.theme.colors.primary};
    outline-offset: 1 px;
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  max-width: 1140px;
  padding: 20px 20px;
  align-items: top;
  flex-direction: row;
  flex-wrap: wrap;
  ${up('lg')} {
    padding: 60px 20px;
  }
`;

const LinkContainer = styled.div`
  width: 100%;
  margin-left: -17px;
  ${up('lg')} {
    width: auto;
    margin-right: 80px;
  }
`;

const Address = styled.div`
  order: 1;
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  ${up('lg')} {
    display: block;
    width: auto;
    order: 2;
    margin-left: auto;
  }

  h3 {
    width: 100%;
  }

  p {
    margin-right: 30px;
  }
`;
const Heading = styled.h3`
  margin-bottom: 1rem;
`;

type NavItemsModel = {
  id: string;
  label: string;
  slug: string | null;
  subNavItems?: NavItemsModel[];
};

interface NavProps {
  navItems?: NavItemsModel[];
}

const Footer: React.FC<NavProps> = ({ navItems }) => {
  return (
    <Wrapper>
      <Row>
        <LinkContainer>
          <a title="Sparkol homepage" href="/en/">
            <Logo />
          </a>
        </LinkContainer>
        <FooterHeading>Footer links</FooterHeading>
        {navItems.map((nav) => (
          <FooterNavGroup key={nav.id} {...nav} />
        ))}
        <Address>
          <Heading>Get in Touch</Heading>
          <p>
            Sparkol Limited - UK <br />
            T: +44 (0)117 428 6117
          </p>
          <p>
            Sparkol Limited - USA <br />
            T: 646-475-1630
          </p>
        </Address>
      </Row>
    </Wrapper>
  );
};

Footer.displayName = 'Footer';

export default Footer;
