import React from 'react';
import styled from 'styled-components';

const SemiCircle = styled.svg`
  width: 1047px;
  height: 1808px;
  bottom: -1290px;
  left: -255px;
  position: absolute;
  fill: rgba(255, 255, 255, 0.4);
`;

const Shape: React.FC = () => {
  return (
    <SemiCircle
      enable-background="new 0 0 1005.19 1735.82"
      viewBox="0 0 1005.19 1735.82"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m277.55 1614.62c-292.81-545.66-91.26-1225.64 449.42-1515.82l203.28 378.74c-331.82 178.09-454.71 596.97-273.94 933.77z" />
    </SemiCircle>
  );
};

export default Shape;
