/* eslint-disable react/display-name */
import React from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Image from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import LargeCard from '../Card/LargeCard';
import Button from '../Button/Button';
import { getFluidGatsbyImage } from '../../utils/getFluidGatsbyImage';
import StudioLogo from '../Icons/LogoStudio';

export { BLOCKS, INLINES };

const Container = styled.div`
  width: 100%;
  ul {
    margin-top: -1.6rem;
    list-style-type: disc;
    padding-left: 20px;
  }
  ul,
  ol {
    margin-bottom: 2rem;
    li > p {
      margin-bottom: 0;
    }
  }
  > :last-child {
    margin-bottom: 0;
  }
`;

const RichTextParagraph = styled.p`
  max-width: 100%;
  ${up('md')} {
    max-width: 700px;
  }
`;

interface nodeProps {
  data: {
    target: {
      fields: {
        description: [];
        file: [];
        richTextBody: [];
        image: {
          fields: [];
          fluid: [];
        };
        buttons: [];
      };
      sys: {
        contentType: {
          sys: { id: string };
        };
      };
    };
  };
}

export const options = {
  renderNode: {
    // eslint-disable-next-line
    [BLOCKS.PARAGRAPH]: (node, children: React.FC) => {
      return <RichTextParagraph>{children}</RichTextParagraph>;
    },
    // eslint-disable-next-line
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { fields, sys } = node.data.target;
      const { id } = sys.contentType.sys;
      switch (id) {
        case 'card': {
          const {
            title,
            alignment,
            imagerySize,
            video,
            richTextBody = {},
            image = {},
            hoverImage = {},
            buttons = {},
          } = fields;
          image.fluid = getFluidGatsbyImage(image.fields, {});
          hoverImage.fluid = getFluidGatsbyImage(hoverImage.fields, {});
          const buttonArray = buttons.length ? buttons.map((button) => button.fields) : [];
          const videoFields = video?.fields ? video.fields : false;
          if (videoFields) {
            videoFields.url = video.fields.videoUrl;
          }

          return (
            <LargeCard
              alignment={alignment}
              image={image}
              hoverImage={hoverImage}
              richTextBody={{ json: richTextBody }}
              buttons={buttonArray}
              isRichtext={true}
              imagerySize={imagerySize}
              video={videoFields}
            />
          );
        }
        case 'logo': {
          const { logo } = fields;
          return <StudioLogo type={logo.toLowerCase()} />;
        }
        case 'button': {
          const { label, url } = fields;
          return <Button variation="default" color="purple" url={url} label={label} />;
        }
      }
    },

    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { description, file } = node.data.target.fields;
      const contentTypes = file.contentType;
      const contentGroups = contentTypes.split('/')[0];

      switch (contentGroups) {
        case 'image': {
          const fluid = getFluidGatsbyImage(node.data.target.fields, {});
          return (
            <div>
              <Image imgStyle={{ objectFit: 'contain' }} fluid={fluid} alt={description} />
            </div>
          );
        }

        case 'video': {
          const videoAssets = {
            file: file,
          };

          return <video playsInline={true} src={videoAssets.file.url} controls={true} />;
        }
      }
    },
    // eslint-disable-next-line
    [INLINES.HYPERLINK]: (node) => {
      return <a href={node.data.uri}>{node.content[0].value}</a>;
    },
  },
};
// eslint-disable-next-line
const RichText = ({ richTextBody, optionOverrides = null }) => {
  let body;
  try {
    body = documentToReactComponents(richTextBody.json, optionOverrides || options);
  } catch (err) {
    // eslint-disable-next-line no-console

    console.log(err);
    return null;
  }

  return <Container>{body}</Container>;
};

RichText.defaultProps = {
  horizontalAlignment: 'center',
  optionOverrides: null,
};

RichText.displayName = 'RichText';

export default RichText;
