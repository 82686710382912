import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import theme from '../../assets/theme';

const SectionHeadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SectionHeading = styled.h2`
  color: ${(props) => theme.themes[props.theme].text};
  display: inline-flex;
  width: 100%;
  max-width: 1140px;
  padding: 0 20px;
  margin-top: 100px;
  margin-bottom: 0;
  justify-content: center;
`;

const SectionContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  background-color: ${(props) => theme.themes[props.theme].backgroundColor};
`;

const SectionInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${(props) => (props.constrained ? '920px' : '100%')};
  ${(props) =>
    props.isBlog &&
    `
  width: 740px;
  margin-top: 100px;
  `}
  max-width: 1140px;
  padding: ${(props) => (props.hasPadding ? '60px 20px' : '0 20px;')};

  ${up('md')} {
    flex-direction: row;
    ${(props) => props.isBlog && 'flex-direction: column;'};
    & > div:last-child {
      margin-right: 0;
    }
  }
  ${(props) =>
    props.isBlog &&
    `
    h1 {
      font-family: 'GT Walsheim','Arial', 'Helvetica', sans-serif;
    }

    ul, ol {
      margin: -1.6rem 0 2rem 0;
      padding-left: 20px;
    }

    ul {
      list-style-type: disc;
    }

    ol{
      list-style-type: decimal;
    }

    img{
      max-width: 100%;
    }
  `}
`;

interface SectionProps {
  title: string;
  showTitle: boolean;
  backgroundColor: string;
  width: string;
  children: any;
  sectionId?: false | string;
}

const Section: React.FC<SectionProps> = ({
  title,
  showTitle = false,
  backgroundColor = 'default',
  children,
  width,
  sectionId = false,
}) => {
  const childArray = React.Children.toArray(children);
  const componentTypes = childArray.map((child) => child?.type?.displayName);
  const noSection = ['Hero', 'HeroSubPage'];
  const hasSection = componentTypes.some((type) => !noSection.includes(type));
  const isConstrained = width === 'Constrained';
  const isBlog = componentTypes.some((type) => type === 'blog');

  const componentSectionPadding = ['RichText', 'Video'];
  const hasPadding = componentTypes.some((type) => componentSectionPadding.includes(type));

  const components = hasSection ? (
    <SectionContainer id={sectionId} theme={backgroundColor || 'default'}>
      {title && showTitle && (
        <SectionHeadingContainer>
          <SectionHeading theme={backgroundColor || 'default'}>{title}</SectionHeading>
        </SectionHeadingContainer>
      )}
      <SectionInner constrained={isConstrained} isBlog={isBlog} hasPadding={hasPadding}>
        {children}
      </SectionInner>
    </SectionContainer>
  ) : (
    children
  );

  return components;
};

export default Section;
