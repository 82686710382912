import React from 'react';
import styled from 'styled-components';
import urlParser from 'js-video-url-parser';
import { up } from 'styled-breakpoints';
import { graphql } from 'gatsby';

const Container = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  height: auto;
`;

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

interface VideoIframeProps {
  url: string;
  autoPlay: boolean;
  loop: boolean;
  showControls: boolean;
}

const Video: React.FC<VideoIframeProps> = ({ url, autoPlay, loop, showControls }) => {
  const videoUrlConfig = `autoplay=${autoPlay ? '1' : '0'}&loop=${loop ? '1' : '0'}`;
  const { provider, id } = urlParser.parse(url);
  let videoUrl;

  switch (provider) {
    case 'vimeo':
      videoUrl = `https://player.vimeo.com/video/${id}`;
      break;
    case 'youtube':
      videoUrl = `https://www.youtube.com/embed/${id}`;
      break;
  }

  videoUrl += `?${videoUrlConfig}`;

  return (
    <Container>
      <Iframe key={videoUrl} src={videoUrl} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
    </Container>
  );
};

export const query = graphql`
  fragment Video on ContentfulVideo {
    url: videoUrl
    autoPlay
    loop
    showControls
  }
`;

Video.displayName = 'Video';

export default Video;
