import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image/withIEPolyfill';
import 'intersection-observer';
import { up } from 'styled-breakpoints';
import { useInView } from 'react-intersection-observer';

import { Button, RichText, Video } from '..';

const getColor = (value) => {
  const colors = ['default', 'green', 'blue', 'purple'];
  return colors[value];
};

const BodyText = styled.div`
  width: 100%;
  order: 2;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  p {
    padding: 0 20px;
    ${up('lg')} {
      padding: 0 60px;
    }
  }
`;

const BodyImage = styled.div`
  width: 100%;
  padding: 0 40px;
  order: 1;
  margin-bottom: 30px;
  overflow: hidden;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: auto;
  a,
  button {
    flex-grow: 1;
    &:nth-child(1) {
      margin-right: 1px;
    }
    &:nth-child(2) {
      &:before {
        content: '';
        height: 100%;
        width: 1px;
        background: white;
        margin-left: -1px;
        position: absolute;
        left: 0;
      }
    }
  }
`;

const CardContainer = styled.div`
  display: flex;
  margin: 0 30px 60px 0;
  background-color: #fff;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  ${up('md')} {
    width: calc(50% - 15px);
    padding: 40px 0 0 0;
    margin: 0 30px 120px 0;
    transition: 250ms ease-in;
    transform: ${(props) => (props.inView ? 'translateY(0)' : 'translateY(200px)')};
  }
  @media screen and (prefers-reduced-motion: reduce) {
    transform: none;
  }
`;

type ButtonModel = {
  label: string;
  url: string;
};
interface CardProps {
  richTextBody: unknown;
  image: unknown;
  colourPalette?: number;
  alignment?: 'left' | 'right';
  buttons?: ButtonModel[];
  variation?: string;
  buttonIcon?: unknown;
  video?: unknown;
}

const MediumCard: React.FC<CardProps> = ({
  richTextBody,
  image,
  colourPalette = 0,
  buttons,
  alignment = 'right',
  video,
}) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const buttonColor = getColor(colourPalette);

  return (
    <CardContainer ref={ref} alignment={alignment} variation={colourPalette} inView={inView}>
      <BodyText>
        <RichText richTextBody={richTextBody} />
        <ButtonsContainer>
          {buttons?.map((button, index) => (
            <Button key={index} label={button.label} url={button.url} color={buttonColor} variation="fluid" />
          ))}
        </ButtonsContainer>
      </BodyText>
      <BodyImage>
        {video ? (
          <Video url={video.url} autoPlay={video.autoPlay} loop={video.loop} showControls />
        ) : (
          <Image imgStyle={{ objectFit: 'contain' }} fluid={image.fluid} alt={image.description} />
        )}
      </BodyImage>
    </CardContainer>
  );
};

export default MediumCard;
