import React from 'react';
import styled from 'styled-components';

const Logo = styled.svg`
  fill: ${(props) => props.fillColor};
`;

interface LogoProps {
  fillColor?: string;
  className?: string;
}

const LogoFull: React.FC<LogoProps> = ({ fillColor = 'white', className }) => {
  return (
    <Logo
      className={className}
      fillColor={fillColor}
      width="120px"
      height="55px"
      viewBox="0 0 750 344.25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m557.777 143.358h-16.438a6.475 6.475 0 0 0 -12.949 0h-16.438a22.913 22.913 0 0 1 45.825 0z" />
        <path d="m642.416 143.358h-16.438a6.475 6.475 0 0 0 -12.95 0h-16.438a22.913 22.913 0 0 1 45.826 0z" />
        <path d="m602.463 196.939-19.163-18.956a49.822 49.822 0 0 1 69.762-71.148l4.152 4.115-11.576 11.672-4.085-4.051a33.149 33.149 0 0 0 -23.17-9.351h-.138a33.384 33.384 0 0 0 -23.377 57.085l19.157 18.949z" />
        <path d="m576.98 251.467a49.855 49.855 0 0 1 -35.128-14.313 48.905 48.905 0 0 1 -14.8-34.95l16.439-.066a32.58 32.58 0 0 0 9.873 23.282 33.517 33.517 0 0 0 23.619 9.61h.142l.066 16.437z" />
        <path d="m475.6 188.888a11.706 11.706 0 0 1 -11.69-11.688v-56.749h-14.525v56.749a26.247 26.247 0 0 0 26.215 26.212h.9v-14.524z" />
        <path d="m187.769 142.037a24.619 24.619 0 0 0 -15.173 5.016l-.806.62v-4.314h-13.6v82.141h13.91v-27.837l.807.623a24.75 24.75 0 0 0 15.477 5.146c16.61 0 28.666-12.953 28.666-30.8 0-8.594-2.891-16.377-8.14-21.914a28.7 28.7 0 0 0 -21.141-8.681zm-.305 48.2c-9.522 0-15.674-6.91-15.674-17.6 0-8.431 4.842-17.5 15.47-17.5 9.522 0 15.675 6.871 15.675 17.5 0 10.525-6.218 17.6-15.471 17.6z" />
        <path d="m223.275 172.836c0 8.595 2.891 16.377 8.141 21.913a28.693 28.693 0 0 0 21.135 8.683 24.615 24.615 0 0 0 15.173-5.017l.806-.62v4.315h13.6v-58.751h-13.906v4.441l-.807-.622a24.751 24.751 0 0 0 -15.477-5.145c-16.609.004-28.665 12.957-28.665 30.803zm45.255 0c0 8.43-4.841 17.5-15.471 17.5-9.521 0-15.673-6.87-15.673-17.5 0-10.53 6.217-17.605 15.47-17.605 9.522 0 15.674 6.91 15.674 17.605z" />
        <path d="m305.759 147.171-.807.622v-4.434h-13.806v58.751h13.907v-30.19c0-7.085 3.182-14.655 12.115-14.655h7.309v-13.906h-7.411a18.331 18.331 0 0 0 -11.307 3.812z" />
        <path d="m384.308 143.387h-18.238l-19.053 20.932v-43.868h-13.908v81.687h13.908v-18.758l5.464-5.842 15.612 24.6h16.112l-22.189-34.928z" />
        <path d="m411.844 142.1c-17.443 0-30.6 13.2-30.6 30.7a30.809 30.809 0 0 0 8.685 21.976 30.417 30.417 0 0 0 21.811 8.721c17.443 0 30.6-13.2 30.6-30.7s-13.111-30.697-30.496-30.697zm-.1 48.2c-9.647 0-16.384-7.2-16.384-17.5 0-10.142 6.89-17.5 16.384-17.5 9.707 0 16.488 7.2 16.488 17.5s-6.783 17.506-16.49 17.506z" />
        <path d="m121.849 203.333a28.8 28.8 0 0 1 -19.888-7.889 29.206 29.206 0 0 1 -9.171-21.2v-.707h13.945v.707a15.147 15.147 0 0 0 16.125 15.114 15.285 15.285 0 0 0 14.145-14.3c.014-.262.021-.526.022-.79a12.768 12.768 0 0 0 -9.245-11.986l-16.75-5.056a23.378 23.378 0 0 1 -16.772-22.486c.006-.472.028-.961.062-1.453a25.231 25.231 0 0 1 50.394 1.842l-13.946.006a11.285 11.285 0 0 0 -22.537-.855c-.015.211-.026.438-.03.664a9.392 9.392 0 0 0 6.858 8.935l16.751 5.056a26.794 26.794 0 0 1 19.16 25.368c0 .492-.013.982-.039 1.475a29.035 29.035 0 0 1 -27.209 27.5q-.941.054-1.875.055z" />
      </g>
    </Logo>
  );
};

export default LogoFull;
