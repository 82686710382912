import React from 'react';
import { Helmet } from 'react-helmet';
import { withAssetPrefix } from 'gatsby';

const Favicon: React.FC = () => {
  return (
    <Helmet>
      <link rel="apple-touch-icon" sizes="180x180" href={withAssetPrefix('/apple-touch-icon.png')} />
      <link rel="icon" type="image/png" sizes="32x32" href={withAssetPrefix('/favicon-32x32.png')} />
      <link rel="icon" type="image/png" sizes="16x16" href={withAssetPrefix('/favicon-16x16.png')} />
      <link rel="manifest" href={withAssetPrefix('/site.webmanifest')} />
      <link rel="mask-icon" href={withAssetPrefix('/safari-pinned-tab.svg')} color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff"></meta>
    </Helmet>
  );
};

export default Favicon;
