// @TODO
// breakpoints
// space
// sizes
// radii
// zIndices

const breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

const colors = {
  primary: '#ff3377',
  grey: '#ebebeb',
  white: '#fff',
  black: '#000',
  green: ['#bfe8bf', '#7DBB98', '#4B895B'],
  blue: ['#F2E376', '#DAB419', '#27A8CB'],
  purple: ['#DEBAE6', '#A863BB', '#6F2689'],
  textBlack: '#1f201d',
};

const buttons = {
  variation: {
    default: `
      color: ${colors.white};
      background-color: ${colors.primary};
      &:hover {
        color: ${colors.primary};
        background-color: ${colors.white};
        &:after {
          border: 1px solid ${colors.primary};
        }
        svg{
          fill: ${colors.primary};
        }
      }
    `,
    green: `
      color: ${colors.white};
      background-color: ${colors.green[2]};
      &:hover {
        color: ${colors.green[2]};
        background-color: ${colors.white};
        svg{
          fill: ${colors.green[2]};
        }
      }
    `,
    blue: `
      color: ${colors.white};
      background-color: ${colors.blue[2]};
      &:hover {
        color: ${colors.blue[2]};
        background-color: ${colors.white};
        svg{
          fill: ${colors.blue[2]};
        }
      }
    `,
    purple: `
      color: ${colors.white};
      background-color: ${colors.purple[2]};
      &:hover {
        color: ${colors.purple[2]};
        background-color: ${colors.white};
        svg{
          fill: ${colors.purple[2]};
        }
      }
    `,
  },
};

const headings = {
  variation: {
    default: `
      h1, h2, h3, h4, h5, h6 {
        color: ${colors.primary};
      }
    `,
    green: `
      h1, h2, h3, h4, h5, h6 {
        color: ${colors.textBlack};
      }
    `,
    yellow: `
      h1, h2, h3, h4, h5, h6 {
        color: ${colors.textBlack};
      }
    `,
    purple: `
      h1, h2, h3, h4, h5, h6 {
        color: ${colors.textBlack};
      }
    `,
  },
};

const theme = {
  colors,
  themes: {
    pink: {
      text: colors.white,
      backgroundColor: colors.primary,
    },
    grey: {
      text: colors.textBlack,
      backgroundColor: colors.grey,
    },
    white: {
      text: colors.textBlack,
      backgroundColor: colors.white,
    },
    default: {
      text: colors.textBlack,
      backgroundColor: 'transparent',
    },
  },
  breakpoints,
  buttons,
  headings,
};

export default theme;
