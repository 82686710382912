import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;
const Nav = styled.nav``;
const Heading = styled.h3`
  margin-bottom: 1rem;
`;
const NavItems = styled.ul``;
const NavItem = styled.li`
  a,
  button {
    padding: 6px 0;
    display: inline-flex;
    &:hover {
      text-decoration: underline ${(props) => props.theme.colors.primary};
    }
  }
`;

type NavGroupItemsModel = {
  id: string;
  label: string;
  slug: string | null;
};

interface NavGroupProps {
  id: string;
  label: string;
  slug: string | null;
  subNavItems?: NavGroupItemsModel[];
}

const NavGroup: React.FC<NavGroupProps> = ({ id, label, slug, subNavItems, className }) => {
  return (
    <Wrapper className={className}>
      <Heading>{label}</Heading>
      <Nav role="navigation" aria-label={label}>
        <NavItems>
          {subNavItems.map((item, index) => (
            <NavItem key={`i${index}`}>
              <a key={`l${index}`} href={item.slug}>
                {item.label}
              </a>
            </NavItem>
          ))}
        </NavItems>
      </Nav>
    </Wrapper>
  );
};

export default NavGroup;
