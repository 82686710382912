import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

const GroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 75px 0 150px;
  margin: -40px 0 0 -40px;
  width: calc(100% + 40px);
  > div {
    width: 90px;
    margin: 40px 0 0 40px;
    ${up('sm')} {
      width: 180px;
    }
  }
`;

interface ImageGroupProps {
  imageGroupItem: [];
}

const ImageGroup: React.FC<ImageGroupProps> = ({ imageGroupItem }) => {
  return (
    <GroupContainer>
      {imageGroupItem?.map((image) => (
        <LazyLoad key={image.id} once>
          <Image fluid={image.fluid} alt={image.title} />
        </LazyLoad>
      ))}
    </GroupContainer>
  );
};

export default ImageGroup;

export const query = graphql`
  fragment ImageGroup on ContentfulImageGroup {
    imageGroupItem {
      id
      title
      file {
        details {
          image {
            height
            width
          }
        }
      }
      fluid(maxWidth: 800) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
  }
`;
