import React from 'react';
import styled, { css } from 'styled-components';
import Image from 'gatsby-image';
import LazyLoad from 'react-lazyload';
import { up } from 'styled-breakpoints';
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';

import { Button, RichText, Video } from '..';

const getColor = (value) => {
  const colors = ['default', 'green', 'blue', 'purple'];
  return colors[value];
};

const getAlignmentStyles = (element, alignment, isRichtext) => {
  const style = {
    BodyText: {
      left: css`
        ${up('md')} {
          order: 2;
          padding-left: 20px;
          padding-right: 20px;
        }
        ${up('lg')} {
          padding-left: 90px;
          padding-right: 0;
        }
      `,
      right: css`
        ${up('md')} {
          order: 1;
          padding-right: 20px;
          padding-left: ${isRichtext ? '0' : '90px;'};
        }
        ${up('lg')} {
          padding-right: 90px;
          padding-left: 0;
        }
      `,
    },
    BodyImage: {
      left: css`
        ${up('md')} {
          order: 1;
          padding-left: 0;
        }
      `,
      right: css`
        ${up('md')} {
          order: 2;
          padding-right: 0;
        }
      `,
    },
  };

  return style[element][alignment];
};

const CardFullPage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 60px;
  ${up('md')} {
    ${(props) => !props.isRichtext && 'margin-bottom: 120px;'}
  }
`;

const BodyText = styled.div`
  order: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${up('md')} {
    ${(props) => getAlignmentStyles('BodyText', props.alignment, props.isRichtext)};
    width: ${(props) => (props.size === 'medium' ? '50%' : '67%')};
    padding-top: ${(props) => (props.isRichtext ? '0' : '49px')};
  }
`;

const BodyImage = styled.div`
  position: relative;
  width: 100%;
  order: 1;
  margin-bottom: 30px;
  ${up('md')} {
    ${(props) => getAlignmentStyles('BodyImage', props.alignment, props.isRichtext)};
    padding: 0 20px;
    width: ${(props) => (props.size === 'medium' ? '50%' : '33%')};
    margin-bottom: 0;
  }
`;

const CardContainer = styled.div`
  display: flex;
  ${(props) => props.isRichtext && 'align-items: center;'}
  flex-direction: column;
  margin: 0 30px 90px 0;
  background-color: #fff;
  width: 100%;
  max-width: 1140px;
  margin: 0;

  ${up('md')} {
    flex-direction: row;
    transition: 250ms ease-in;
    transform: ${(props) => (props.inView ? 'translateY(0)' : 'translateY(200px)')};
  }

  @media screen and (prefers-reduced-motion: reduce) {
    transform: none;
  }
`;

const ButtonsContainer = styled.div`
  a,
  button {
    margin: 2rem 1rem 0 0;
  }
`;

const HoverEffect = styled.div`
  transition: opacity 0.5s ease-out;
  opacity: 1;
  ${(props) =>
    props.hover &&
    `
    :hover {
      opacity: 0;
      transition: opacity 0.5s ease-out;
    }
  `}
`;

const HoverImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  ${up('md')} {
    padding-right: 20px;
  }
`;

type ButtonModel = {
  label: string;
  url: string;
};
interface CardProps {
  richTextBody: unknown;
  image?: unknown;
  hoverImage?;
  colourPalette?: number;
  alignment?: 'left' | 'right';
  buttons?: ButtonModel[];
  isRichtext?: boolean;
  imagerySize?: string;
  buttonIcon?: unknown;
  video: unknown;
}

const LargeCard: React.FC<CardProps> = ({
  richTextBody,
  image,
  colourPalette = 0,
  buttons,
  alignment = 'right',
  isRichtext = false,
  imagerySize = 'medium',
  video,
  hoverImage,
}) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });
  const buttonColor = getColor(colourPalette);
  const hasHover = !!hoverImage?.fluid;
  // console.log('hasHover', hasHover);
  return (
    <CardFullPage isRichtext={isRichtext}>
      <CardContainer isRichtext={isRichtext} ref={ref} inView={inView}>
        <BodyText size={imagerySize} isRichtext={isRichtext} alignment={alignment}>
          <RichText richTextBody={richTextBody} />
          <ButtonsContainer>
            {buttons?.map((button, index) => (
              <Button key={index} label={button.label} url={button.url} color={buttonColor} variation="default" />
            ))}
          </ButtonsContainer>
        </BodyText>
        <BodyImage size={imagerySize} alignment={alignment}>
          <LazyLoad once height="100%">
            {video ? (
              <Video url={video.url} autoPlay={video.autoPlay} loop={video.loop} showControls />
            ) : (
              <>
                {hasHover && (
                  <HoverImage>
                    <Image fluid={hoverImage?.fluid} alt={hoverImage?.description} />
                  </HoverImage>
                )}
                <HoverEffect hover={hasHover}>
                  <Image fluid={image?.fluid} alt={image?.description} />
                </HoverEffect>
              </>
            )}
          </LazyLoad>
        </BodyImage>
      </CardContainer>
    </CardFullPage>
  );
};

export default LargeCard;
