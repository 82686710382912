import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { withAssetPrefix } from 'gatsby';

import theme from '../assets/theme';
import GlobalStyle from '../components/GlobalStyle';
import Favicon from '../components/Favicon';

import CookieConsentBar from './CookieConsentBar/CookieConsentBar';

interface LayoutProps {
  title: string;
  metaDescription: string;
}

const Layout: React.FC<LayoutProps> = ({ title, metaDescription, children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Helmet
          htmlAttributes={{
            lang: 'en',
          }}
        >
          {title && <title>{title} - Sparkol</title>}
          <meta name="viewport" content="width=device-width"></meta>
          {metaDescription && <meta name="description" content={metaDescription} />}
          {/* open graph tags @todo */}
          {/* twitter tags @todo */}
          <link rel="stylesheet" href={withAssetPrefix('fonts/fonts.css')} media="all"></link>
        </Helmet>
        <Favicon />
        <GlobalStyle />
        {children}
        <CookieConsentBar />
      </Fragment>
    </ThemeProvider>
  );
};

export default Layout;
