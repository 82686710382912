import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { RichText } from '../';

const Blog: React.FC<{ children }> = ({ children }) => {
  return <></>;
};

Blog.displayName = 'blog';

export default Blog;
